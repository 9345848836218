<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-form
      label-width="80px"
      ref="formRef"
      :model="state.form"
      :rules="state.rules"
    >
      <el-form-item label="群组名称" prop="name">
        <el-input
          v-model="state.form.name"
          placeholder="请输入群组名称，方便区分投放群组"
        />
      </el-form-item>
      <el-form-item label="短线模板" prop="smsScene">
        <el-select
          class="w-percent-100 m-2"
          v-model="defaultLabel"
          placeholder="请选择要关联的门票"
          @change="handleChangeSelect"
        >
          <el-option
            v-for="item in state.typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="群组备注">
        <el-input v-model="state.form.remark" placeholder="暂无备注" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">
        确认{{ title == '编辑' ? '编辑' : '添加' }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref, getCurrentInstance, onMounted } from 'vue'
  import { createTicketsThrow } from '@/api/exhibitionManage'
  import { debounce } from '@/utils/debounce'
  const props = defineProps({
    title: {
      type: String,
      default: '编辑',
    },
  })
  const { proxy } = getCurrentInstance()
  const dialogFormVisible = ref(false)
  const formRef = ref(null)
  const defaultLabel = ref('默认投放模板')
  const state = reactive({
    form: {
      name: '',
      remark: '',
      batchNo: '',
      delFlag: false,
      ticketCode: '',
      smsScene: 'SMS_DIGITAL_TICKET_SEND',
    },
    typeOptions: [
      {
        label: '默认投放模板',
        value: 'SMS_DIGITAL_TICKET_SEND',
      },
      {
        label: '行野行也',
        value: 'SMS_XINGYE_TICKET',
      },
    ],
    rules: {
      name: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入群组名称，方便区分投放群组',
        },
      ],
      smsScene: [
        {
          required: true,
          trigger: 'blur',
          message: '请选择',
        },
      ],
    },
  })
  const emit = defineEmits(['noticeRefresh'])
  // 关闭
  const handleClose = () => {
    dialogFormVisible.value = false
  }
  // 打开
  const handleOpen = (row = state.form) => {
    console.log('row', row)
    if (props.title == '添加') {
      defaultLabel.value = '默认投放模板'
    }
    state.form = row
    dialogFormVisible.value = true
    filterSelectField()
  }
  // 下拉事件
  const handleChangeSelect = (e) => {
    state.form.smsScene = e
    state.typeOptions.forEach((item) => {
      if (item.value == e) {
        defaultLabel.value = item.label
      }
    })
  }
  // 保存
  const handleSave = debounce(() => {
    let params = {
      ...state.form,
    }
    console.log('params', params)
    formRef.value.validate(async (valid) => {
      if (valid) {
        let resp = await createTicketsThrow(params)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        emit('noticeRefresh')
        handleClose()
      }
    })
  })
  const filterSelectField = () => {
    state.typeOptions.forEach((item) => {
      if (item.label == defaultLabel.value) {
        state.form.smsScene = item.value
      }
    })
  }
  onMounted(() => {})
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
